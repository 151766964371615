<template>
  <v-row>
    <v-col>Chain</v-col>
  </v-row>
</template>

<script>
export default {
  name:'ChainAnalytics',
}
</script>
