<template>
  <v-container fluid class="m-auto">
    <v-card class="rounded common_background ma-2 mx-md-4">
      <v-tabs vertical slider-size="10" color="secondary_3">
        <v-tab>
          <div class="fill-width text-left">
            {{ $t('Price') }}
          </div>
        </v-tab>
        <v-tab>
          <div class="fill-width text-left">
            {{ $t('ETHAccounts') }}
          </div>
        </v-tab>
        <v-tab>
          <div class="fill-width text-left">
            {{ $t('Chain') }}
          </div>
        </v-tab>
        <v-tab-item>
          <v-card flat class="card_bg">
            <v-card-text class="pa-0">
              <Loader v-if="!iframePrice.loaded" :height="`500px`"/>
              <iframe
                v-show="iframePrice.loaded"
                ref="news"
                :src="iframePrice.src"
                frameborder="0"
                style="height:80vh"
                class="news fill-width"
                @load="loadPrice"
              >
              </iframe>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="card_bg">
            <v-card-text class="pa-0 text-center">
              <Loader v-if="!iframeEthAccounts.loaded" :height="`500px`"/>
              <iframe
                v-show="iframeEthAccounts.loaded"
                ref="ethaccounts"
                :src="iframeEthAccounts.src"
                frameborder="0"
                style="height:80vh; width:80%;"
                class="news fill-width"
                @load="loadEthAccounts"
              >
              </iframe>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat height="400" class="card_bg">
            <v-card-text>
              <Chain />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import Chain from '@/components/organisms/analytics/Chain'
import Loader from '@/components/molecules/Loader'

export default {
  name:'Analytics',
  components: {
    Chain,
    Loader,
  },
  data: () => ({
    loading: true,
    iframePrice: {
      src: 'https://app.powerbi.com/view?r=eyJrIjoiZWY0MzEwMWYtYTQ3Yi00MzE0LWE2YmYtNzYzMGY2MGYyYzA0IiwidCI6ImVmMTNhM2ZjLTE4M2EtNGJjOS05ZjRmLTEzOWQ2OWVkNDkyYiJ9',
      loaded: false,
    },
    iframeEthAccounts: {
      src: 'https://datastudio.google.com/embed/reporting/77f49e2d-2eee-4028-ac6a-a26b6bc28d52/page/q1FUC',
      loaded: false,
    },
  }),
  methods: {
    loadPrice(ev) {
      this.iframePrice.loaded = true
      this.loading = false
    },
    loadEthAccounts(ev) {
      this.iframeEthAccounts.loaded = true
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.view-analytics {
  display: flex;
  justify: center;
  align-item: center;
}
</style>
